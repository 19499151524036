/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
'use strict';

// ALL Analytics Codes Here 
// ===========================================
var Desktop = $(window).width() > 767;
var $adjustEnable = false;
var CUSTOM_MOENGAGE = require('./moengage');
var CUSTOM_SNAPCHAT = require('./snapchat');

$(document).ready(function () {
    $.adjustTrack = {
        addToCart: '5tdste',
        removeToCart: 'kln0jq',
        addToWishlist: '55kn0g',
        removeToWishlist: '7yknz6',
        cartAddToCart: 'hifxp7',
        cartRemoveToCart: 'ay3uxt',
        deleteFromCart: '2wyyd4',
        loginFail: 'xymiwz',
        loginSuccess: 'nr1abc',
        registerSuccess: 'qh08ix',
        registerFail: 'f5mxzv',
        paymentFail: 'ph5hky',
        paymentSuccess: '4z0dn8',
        continuePayment: 'yr9xr7',
        selectShipping: 'ds0pmn',
        iPointsFullPayment: 'rqnf1r',
        selectiPointsPayment: '6axbnz',
        newCardPayment: 'n394ea',
        saveCardPayment: 'b9f2xr',
        beginCheckout: 'rfwnbz',
        changeLanguage: 'ueeb47',
        changeCurrency: '9g379x',
        OTPFail: 'vpv9h0',
        OTPSuccess: 'qkd1de',
        logout: 'ws0gt0',
        useApp: 'y7ft3m'
    };

    if (typeof Adjust !== 'undefined' && Adjust) {
        var environment;

        if (window.location.href.indexOf('idealz.com') > -1) {
            environment = 'production';
        } else {
            environment = 'sandbox';
        }
        Adjust.initSdk({
            appToken: 'k80f0k6puups',
            environment: environment,
            logLevel: 'error'
        });

        $adjustEnable = true;
    }

    if (!('gtag' in window)) {
        return;
    }

    var Analytics = {
        gATrack: function ($verb, $type, $category, $event, $name) {
            gtag('event', $event, {
                event_category: $category,
                event_label: $name,
                value: $type
            });
        },
        gaSendEvent: function ($category, $event, $name) {
            Analytics.gATrack('send', 'event', $category, $event, $name);
        },
        adjustSendEvent: function (jso) {
            if ($adjustEnable) {
                Adjust.trackEvent(jso);
            }
        }
        // infobhipTrack: function ($id, jso) {
        //     if (typeof pe !== 'undefined') {
        //         pe.track($id, jso);
        //     }
        // },
        // infobhipLogin: function (jso) {
        //     if (typeof pe !== 'undefined') {
        //         pe.setPerson(jso, 2000 );
        //     }
        // },
        // infobhipLogout: function () {
        //     if (typeof pe !== 'undefined') {
        //         pe.forgetPerson();
        //     }
        // }
    };

    var gtagElems = $('span[data-gtag-event]:not(.begin_checkout)');
    var gtagElemsCart = $('span[data-gtag-event].begin_checkout');
    var userID = GTM_CUSTOMER
        ? GTM_CUSTOMER.customerNo
            ? GTM_CUSTOMER.customerNo
            : 'Not Login'
        : null; // eslint-disable-line no-undef
    var userEmail = userID; // eslint-disable-line no-undef

    gtagElems.each(function () {
        var gtagElem = $(this);
        var eventName = gtagElem.data('gtag-event');
        var param = gtagElem.attr('data-gtag-param');
        param = JSON.parse(param);
        gtag('event', eventName, param); // eslint-disable-line no-undef
        if (typeof ttq !== 'undefined' && ttq && eventName === 'purchase') {
            ttq.track('PlaceAnOrder', {
                contents: param.items.map(item => {
                    return {
                        "content_id": item.item_id,
                        "price": item.price,
                        "quantity": item.quantity,
                        "content_name": item.item_name
                    };
                }),
                content_type: 'product',
                value: param.value,
                description: param.transaction_id,
                currency: param.currency
            });
        }
    });

    gtagElemsCart.each(function () {
        var gtagElem = $(this);
        var param = gtagElem.attr('data-gtag-param');
        var param = gtagElem.attr('data-gtag-param');
        param = JSON.parse(param);
        gtag('event', 'view_cart', param);
    });

    // All Custom Tracking Codes goes below
    // ==============================================================

    $(document)
        .on('click', '.js-add-to-wish-list:not(.added)', function () {
            var eventName = $(this).data('gtag-event');
            var param = $(this).attr('data-gtag-param');
            var $module = $(this).attr('data-module');
            param = JSON.parse(param);

            param.event_label = $module + ' - ' + param.items[0].item_name;

            // ttq.track('AddToWishlist');

            if (typeof ttq !== 'undefined' && ttq) {
                ttq.track('AddToWishlist', {
                    contents: [
                        {
                            content_id: param.items[0].item_id,
                            content_name: param.items[0].item_name,
                            quantity: param.items[0].quantity,
                            price: param.items[0].price.toString()
                        }
                    ],
                    content_type: 'product',
                    value: param.items[0].price,
                    currency: param.currency
                });
            }

            if (eventName && param) {
                gtag('event', eventName, param); // eslint-disable-line no-undef);
                Analytics.adjustSendEvent({
                    eventToken: $.adjustTrack.addToWishlist
                });
            }
            CUSTOM_SNAPCHAT.trackUserEvents(CUSTOM_SNAPCHAT.SNAPCHAT_EVENT_NAME.addToWishlist, param);
            CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.addToWishlist, param);
        })
        .on(
            'click',
            '.js-add-to-wish-list.added, .js-delete-wishlist',
            function () {
                var eventName = 'remove_from_wishlist';
                var param = $(this).attr('data-gtag-param');
                var $module = $(this).attr('data-module');
                param = JSON.parse(param);
                param.event_label = $module + ' - ' + param.items[0].item_name;

                if (eventName && param) {
                    gtag('event', eventName, param); // eslint-disable-line no-undef);
                    Analytics.adjustSendEvent({
                        eventToken: $.adjustTrack.removeToWishlist
                    });
                }
                CUSTOM_SNAPCHAT.trackUserEvents(CUSTOM_SNAPCHAT.SNAPCHAT_EVENT_NAME.removeFromWishlist, param);
                CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.removeFromWishlist, param);
            }
        )
        .on('click', '.js-register-redirect', function () {
            gtag('event', 'click', {
                // eslint-disable-line no-undef
                event_category: 'engagement',
                event_label:
                    'Register Success Redirect - ' + Desktop
                        ? 'Desktop'
                        : 'Mobile'
            });
            CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.registrationSuccess);
        });

    $(document).on('click', '.removeFromFavourites-btn', function () {
        var eventName = 'remove_from_wishlist';
        var param = $(this).attr('data-gtag-param');
        param = JSON.parse(param);
        param.event_label = Desktop
            ? 'Wishlist' + ' - ' + param.event_label
            : param.event_label;

        if (eventName && param) {
            gtag('event', eventName, param); // eslint-disable-line no-undef);
            Analytics.adjustSendEvent({
                eventToken: $.adjustTrack.removeToWishlist
            });
        }
        CUSTOM_SNAPCHAT.trackUserEvents(CUSTOM_SNAPCHAT.SNAPCHAT_EVENT_NAME.removeFromWishlist, param);
        CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.removeFromWishlist, param);
    });

    $(document).on('login:error', function (event, data) {
        var $code = data.code ? ' - Code = ' + data.code : '';
        gtag('event', 'login', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label:
                'Login Fail - Email=' + data.email + ' - ' + data.error + $code
        });

        // Analytics.infobhipTrack('loginfailure', { error: data.error, email: data.email });
        // Analytics.infobhipTrack('LoggedinID', { loggedin: false, error: data.error });

        Analytics.adjustSendEvent({
            eventToken: $.adjustTrack.loginFail
        });
        CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.loginFailed, data);
    });

    $(document).on('login:success', function (event, data) {
        var $code = data.code ? ' - Code = ' + data.code : '';
        gtag('event', 'login', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label: 'Login Success - Email=' + data.email + $code
        });
        Analytics.adjustSendEvent({
            eventToken: $.adjustTrack.loginSuccess,
        });
        CUSTOM_SNAPCHAT.trackUserEvents(CUSTOM_SNAPCHAT.SNAPCHAT_EVENT_NAME.login, data);
        // Analytics.infobhipLogin({ email: data.email });
        // Analytics.infobhipTrack('LoggedinID', { loggedin: true });
    });

    $(document).on('logout:success', function () {
        gtag('event', 'logout', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label: userEmail + ' Logout'
        });
        Analytics.adjustSendEvent({
            eventToken: $.adjustTrack.logout
        });
        CUSTOM_SNAPCHAT.trackUserEvents(CUSTOM_SNAPCHAT.SNAPCHAT_EVENT_NAME.logout);
        // Analytics.infobhipLogout();
    });

    $(document).on('register:error', function (event, data) {
        var Device = Desktop ? 'Desktop' : 'Mobile';
        var $code = data.code ? ' - Code = ' + data.code : '';
        gtag('event', 'sign_up', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label:
                Device +
                ' Register Fail - Email= ' +
                data.email +
                ' - First Name = ' +
                data.firstname +
                ' - Last Name = ' +
                data.lastname +
                ' - Country Code = ' +
                data.countryCode +
                ' - Mobile Phone = ' +
                data.phone +
                $code +
                ' - Error:' +
                data.error
        });
        // Analytics.infobhipTrack('registrationfailure', { error: data.error, phone: data.phone, firstname: data.firstname, lastname: data.lastname, country_code: data.countryCode });
        // Analytics.infobhipTrack('Registrationcompleted', { registration_completed: false, failure_msg: data.error });
        Analytics.adjustSendEvent({
            eventToken: $.adjustTrack.registerFail
        });
            CUSTOM_SNAPCHAT.trackUserEvents(CUSTOM_SNAPCHAT.SNAPCHAT_EVENT_NAME.signupFailed, data);
            CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.registrationFailed, data);
    });

    $(document).on('register:success', function (event, data) {
        var Device = Desktop ? 'Desktop' : 'Mobile';
        var $code = data.code ? ' - Code = ' + data.code : '';
        gtag('event', 'sign_up', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label:
                Device +
                ' Register Success - Email= ' +
                data.email +
                ' - First Name = ' +
                data.firstName +
                ' - Last Name = ' +
                data.lastName +
                ' - Country Code = ' +
                data.countryCode +
                ' - Mobile Phone = ' +
                data.mobileNumber +
                ' - origin = ' +
                data.origin ? data.origin : 'Dream Dubai' +
                $code
        });
        // Analytics.infobhipLogin({ email: data.email });
        // Analytics.infobhipTrack('RegistrationSuccess', { email: data.email, phone: data.phone, firstname: data.firstname, lastname: data.lastname, country_code: data.countryCode });
        // Analytics.infobhipTrack('Registrationcompleted', { registration_completed: true });
        Analytics.adjustSendEvent({
            eventToken: $.adjustTrack.registerSuccess
        });
        if (typeof ttq !== 'undefined' && ttq) {
            ttq.track('CompleteRegistration');
        }
        CUSTOM_SNAPCHAT.trackUserEvents(CUSTOM_SNAPCHAT.SNAPCHAT_EVENT_NAME.signup, Object.assign({}, data, { mobileNumber: data.countryCode + data.mobileNumber }));
        CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.registrationSuccess, Object.assign({}, data, { mobileNumber: data.countryCode + data.mobileNumber }));
    });
    $(document).on('promocode:success', function (event, data) {
        if (typeof Moengage !== 'undefined' && Moengage) {
            CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.promoCodeAdded, data);
        }

        CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_SNAPCHAT.SNAPCHAT_EVENT_NAME.addPromo, data);
    });
    $(document).on('promocode:remove', function (event, data) {
        if (typeof Moengage !== 'undefined' && Moengage) {
            CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.promoCodeRemoved, data);
        }
    });

    $(document).on('promocode:failed', function (event, data) {
        if (typeof Moengage !== 'undefined' && Moengage) {
            CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.promoCodeFailed, data);
        }  
        CUSTOM_SNAPCHAT.trackUserEvents(CUSTOM_SNAPCHAT.SNAPCHAT_EVENT_NAME.promoFailed, data);
    });

    $(document).on('verification:success', function (event, data) {
        var Device = Desktop ? 'Desktop' : 'Mobile';
        gtag('event', 'click', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label: Device + ' SMS Send Success - ' + data.phoneNumber
        });
        Analytics.adjustSendEvent({
            eventToken: $.adjustTrack.OTPSuccess
        });
    });

    $(document).on('GTM:passwordFailed', function (event, data) {
        gtag('event', 'click', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label: 'Password Changed Failed - ' + data
        });
    });

    $(document).on('GTM:passwordSuccess', function (event, data) {
        gtag('event', 'click', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label: 'Password Changed Succeess'
        });
    });

    $(document).on('click', '.quickproductview', function (event, data) {
        var $this = $(this);
        var $title = $this.find('h3').text();
        var price = $this.find('h2').text();
        var id = $(this).data('id');
        var parentIndex = $this.closest('.swiper-slide').index() + 1;

        var param = {
            content_type: 'product',
            event_label: 'Quick View Product - ' + id + ' - ' + $title,
            event_category: 'engagement',
            items: [
                {
                    id: id,
                    name: $title,
                    list_name: 'Product View',
                    brand: 'Idealz UAE',
                    category: 'Product View',
                    list_position: parentIndex,
                    price: price.substring(3)
                }
            ]
        };

        if (param) {
            gtag('event', 'view_item', param); // eslint-disable-line no-undef
        }
    });

    $(document).on('click', '.js-platformSource-screen-done-btn', function () {
        var $this = $('.platformSoruce-select-box .active');
        var title = $this.text();
        title = $.trim(title);

        gtag('event', 'click', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label: 'Platform Source - ' + title
        });
    });

    $(document).on('verification:error', function (event, data) {
        var Device = Desktop ? 'Desktop' : 'Mobile';
        gtag('event', 'click', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label:
                Device +
                ' SMS Send Fail - Email= ' +
                data.email +
                ' - First Name = ' +
                data.firstname +
                ' - Last Name = ' +
                data.lastname +
                ' - Mobile Phone = ' +
                data.phoneNumber +
                ' - Error:' +
                data.message
        });
        Analytics.adjustSendEvent({
            eventToken: $.adjustTrack.OTPFail
        });
    });

    $(document).on('click', '#mobile-exiting-user-button', function () {
        var Device = Desktop ? 'Desktop' : 'Mobile';
        Analytics.gaSendEvent(
            'engagement',
            'click',
            Device + ' Homepage Existing User'
        );
    });

    $(document).on('GTM:contactUsError', function (e, i) {
        gtag('event', 'click', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label: 'Contact Us Failed - ' + i
        });
    });

    $(document).on('GTM:contactUsSuccess', function (e, i) {
        gtag('event', 'click', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label: 'Contact Us Success - ' + i
        });
    });

    $(document).on('addBundleOffer:success', function (event, data) {
        const bundleData = {
            discountedValue: data.bundle_details.bundleDiscountValue,
            promoName: data.bundle_details.bundlePromoName,
            promoPrice: data.bundle_details.bundlePromoPrice,
            totalPrice: data.bundle_details.bundleTotalPrice,
            bundleItemID1: data.items[0].item_id,
            bundleItemID2: data.items[1].item_id
        }
        gtag('event', 'add_bundle_offer', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label:
                'Add Bundle Offer - '+ 
                'Discounted Value: ' + bundleData.discountedValue + '- ' +
                'Bundle Promo Name: ' + bundleData.promoName + '- ' +
                'Bundle Promo Price: ' + bundleData.promoPrice + '- ' +
                'Bundle Total Price: ' + bundleData.totalPrice + '- ' +
                'Bundle Item 1: ' + bundleData.bundleItemID1 + '- ' +
                'Bundle Item 2: ' + bundleData.bundleItemID2  + '- ' +
                'userID:'+ userID
        });
        CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.addBundleOffer, bundleData);
    });


    $(document).on('country:success', function (event, data) {
        gtag('event', 'select_content', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label:
                'Change Language - ' + data.localeCode + ' - ' + userID
        });
        Analytics.adjustSendEvent({
            eventToken: $.adjustTrack.changeLanguage
        });
            CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.changeLanguage, data);
    });
    

    $(document).on('currency:success', function (event, data) {
        gtag('event', 'select_content', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label:
                'Change Currency - ' + data.localeCurrencyCode + ' - ' + userID
        });
        Analytics.adjustSendEvent({
            eventToken: $.adjustTrack.changeCurrency
        });
        CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.changeCurrency, data);
    });
    $(document).on('wallet:applied', function (event, data) {
        var isFullPayment = data.isFullPayment
            ? 'Full Credit Payment'
            : 'Partial Credit Payment';
        gtag('event', 'select_content', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label: 'Credit Applied - ' + userID + ' - ' + isFullPayment
        });
        CUSTOM_SNAPCHAT.trackUserEvents(CUSTOM_SNAPCHAT.SNAPCHAT_EVENT_NAME.spentCredits, { success: 1 });
        CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.selectCredit, { toggle: 'on' });
    });
    $(document).on('wallet:removed', function (event, data) {
        gtag('event', 'select_content', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label: 'Credit Removed - ' + userID
        });
        CUSTOM_SNAPCHAT.trackUserEvents(CUSTOM_SNAPCHAT.SNAPCHAT_EVENT_NAME.spentCredits, { success: 0 });
        CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.selectCredit, { toggle: 'off' });
    });

    $(document).on('payment:remove', function (event, data) {
        gtag('event', 'select_content', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label: 'Saved Card Removed - ' + userID + ' - ' + data.card
        });
    });

    $(document).on('click', '.js-handle-session-sharing', function () {
        gtag('event', 'click', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label: 'Switch to Modeshworld'
        });
        CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.switchToModesh);
    });

    $(document).on('notification:success', function (event, data) {
        var optMessage = data.enabled ? 'Opt-in' : 'Opt-out';
        gtag('event', 'click', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label: 'Notification ' + optMessage + ' - ' + data.prefType
        });
    });

    $(document).on(
        'click',
        '#main-campagin-area .swiper-button-next',
        function () {
            Analytics.gaSendEvent(
                'engagement',
                'click',
                'Live Campaigns - Swipe Next'
            );
        }
    );

    $(document).on(
        'click',
        '#main-campagin-area .swiper-button-prev',
        function () {
            Analytics.gaSendEvent(
                'engagement',
                'click',
                'Live Campaigns - Swipe Previous'
            );
        }
    );

    $(document).on(
        'click',
        '.soldout-campaign-area .swiper-button-next',
        function () {
            Analytics.gaSendEvent(
                'engagement',
                'click',
                'Soldout Campaigns - Swipe Next'
            );
        }
    );

    $(document).on(
        'click',
        '.soldout-campaign-area .swiper-button-prev',
        function () {
            Analytics.gaSendEvent(
                'engagement',
                'click',
                'Soldout Campaigns - Swipe Previous'
            );
        }
    );

    $(document).on(
        'click',
        '.winners-campaign-area .swiper-button-next',
        function () {
            Analytics.gaSendEvent(
                'engagement',
                'click',
                'Winner Campaigns - Swipe Next'
            );
        }
    );

    $(document).on(
        'click',
        '.winners-campaign-area .swiper-button-prev',
        function () {
            Analytics.gaSendEvent(
                'engagement',
                'click',
                'Winner Campaigns - Swipe Previous'
            );
        }
    );

    $(document).on('GTM:minitCartHover', function () {
        Analytics.gaSendEvent('engagement', 'hover', 'Mini Cart Open');
    });

    $(document).on('click', '.product-area .swiper-button-next', function () {
        Analytics.gaSendEvent(
            'engagement',
            'click',
            'Product Campaigns - Swipe Next'
        );
    });

    $(document).on('click', '.product-area .swiper-button-prev', function () {
        Analytics.gaSendEvent(
            'engagement',
            'click',
            'Product Campaigns - Swipe Previous'
        );
    });

    $(document).on('click', '.inviteButtonGift', function () {
        Analytics.gaSendEvent('engagement', 'click', 'Invite a friend Button');
    });

    $(document).on('click', '.gtm-login-register', function () {
        Analytics.gaSendEvent('engagement', 'click', 'Login / Register');
    });

    $(document).on('click', '.footer-navigation ul li a', function () {
        var $this = $(this);
        var $text = $this.text();
        Analytics.gaSendEvent('engagement', 'click', 'Footer - ' + $text);
    });

    $(document).on('click', '.profile-menu-links li a', function (e) {
        var $this = $(this);
        var $text = $this.text();
        Analytics.gaSendEvent(
            'engagement',
            'click',
            'Profile - ' + $.trim($text)
        );
    });

    $(document).on('click', '.js-ticket-heading-collapse', function (e) {
        var $this = $(this);
        var $text = $this.find('.titleticket').text();
        Analytics.gaSendEvent(
            'engagement',
            'click',
            'Ticket Expand - ' + $.trim($text)
        );
    });

    $(document).on('GTM:profileupdate', function (e, data) {
        Analytics.gaSendEvent('engagement', 'click', 'Profile Update Success');
        CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.updateProfile, data);
    });

    $('.list-group a').on('click', function () {
        var buttonName = $(this).attr('title');
        gtag('event', 'view_item', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label:
                'Burger Menu - ' +
                buttonName +
                ' - ' +
                userEmail +
                ' - ' +
                userID
        });
    });

    $('nav').on('click', function () {
        var str = location.href;
        var lastIndex = str.lastIndexOf('/');
        var pageName = str.slice(lastIndex + 1, str.length);
        gtag('event', 'view_item', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label:
                'NavBar - ' + pageName + ' - ' + userEmail + ' - ' + userID
        });
    });

    $('#ShareBox a, .shareable-links a').on('click', function () {
        // var medium = $(this).data('share');
        // var pid = $('.social-icons').data('pid');
        // var productName = $('.social-icons').data('product-name');

        var $this = $(this);
        var $parent = $this.closest('.product-detail');
        var campID = $parent.data('pid');
        var $data = $(this).data('share');

        gtag('event', 'share', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label:
                'Level2 - Share - ' +
                'mobile' +
                ' - ' +
                campID +
                ' - ' +
                $data
        });
        CUSTOM_SNAPCHAT.trackUserEvents(CUSTOM_SNAPCHAT.SNAPCHAT_EVENT_NAME.share, { description: 'Level2 - Share - ' +
                'mobile' +
                ' - ' +
                campID +
                ' - ' +
                $data });
    });
    /**
     * trigger event whenever a product is added
     */
    $('body').on('cart:addTOCartGTM', function (e, response) {
        var eventName = $(response).data('gtag-add-event');
        var param = $(response).attr('data-gtag-add-param');
        var $module = $(response).data('module');
        param = JSON.parse(param);

        // if (typeof $module !== 'undefined') {
        //     param.event_label = Desktop
        //         ? $module + ' - ' + param.event_label
        //         : param.event_label;
        // } else {
        //     param.event_label = Desktop ? param.event_label : param.event_label;
        // }

        Analytics.adjustSendEvent({
            eventToken: $.adjustTrack.addToCart
        });

        if (eventName && param) {
            gtag('event', eventName, param); // eslint-disable-line no-undef
            // Analytics.infobhipTrack('addtocart', {
            //     addtocart: true,
            //     quantity: param.items[0].quantity,
            //     product_price: param.items[0].price.toString(),
            //     prize_title: param.items[0].item_name,
            //     product_name: param.items[0].item_id,
            //     campaign_id: param.items[0].item_id,
            //     currency: param.currency,
            //     price_num: param.items[0].price
            // });
        }

        if (typeof ttq !== 'undefined' && ttq) {
            ttq.track('AddToCart', {
                contents: [
                    {
                        content_id: param.items[0].item_id,
                        content_name: param.items[0].item_name,
                        quantity: param.items[0].quantity,
                        price: param.items[0].price.toString()
                    }
                ],
                content_type: 'product',
                value: param.items[0].price,
                currency: param.currency
            });
        }
        CUSTOM_SNAPCHAT.trackUserEvents(CUSTOM_SNAPCHAT.SNAPCHAT_EVENT_NAME.addCart, param);
        CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.addToCart, param);
    });

    $(document).on('initCheckout', function () {
        var $gtagElem = $('span[data-gtag-event].begin_checkout');
        var param = $gtagElem.attr('data-gtag-param');
        param = JSON.parse(param);
        var newArr = [];

        $.each(param.items, function (index, item) {
            var newObj = {};
            newObj.content_id = item.item_id;
            newObj.content_name = item.item_name;
            newObj.quantity = item.quantity;
            newObj.price = item.price;

            newArr.push(newObj);
        });

        if (typeof ttq !== 'undefined' && ttq) {
            ttq.track('InitiateCheckout', {
                contents: newArr,
                content_type: 'product',
                value: param.value,
                currency: param.currency
            });
        }
        CUSTOM_SNAPCHAT.trackUserEvents(CUSTOM_SNAPCHAT.SNAPCHAT_EVENT_NAME.startCheckout, param);
        CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.initiateCheckout, param);
    });

    $('body').on('GTM:cartUpdate', function (e, i) {
        var param;
        var eventName;
        var eventNameParent;

        if (i.addToCartGtag.event) {
            eventNameParent = i.addToCartGtag;
            eventName = eventNameParent.event;
            param = JSON.parse(eventNameParent.param);

            if (typeof Adjust !== 'undefined' && Adjust) {
                Adjust.trackEvent({
                    eventToken: $.adjustTrack.cartAddToCart
                });
            }
            CUSTOM_SNAPCHAT.trackUserEvents(CUSTOM_SNAPCHAT.SNAPCHAT_EVENT_NAME.addCart, param);
            CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.addToCart, param);
        } else {
            eventNameParent = i.removeFromCartGtag;
            eventName = 'remove_quantity';
            param = JSON.parse(eventNameParent.param);

            Analytics.adjustSendEvent({
                eventToken: $.adjustTrack.cartRemoveToCart
            });
            CUSTOM_SNAPCHAT.trackUserEvents(CUSTOM_SNAPCHAT.SNAPCHAT_EVENT_NAME.removeFromCart, param);
            CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.removeFromCart, param);
        }
        gtag('event', eventName, JSON.parse(eventNameParent.param));

        // Analytics.infobhipTrack('updatecart', {
        //     addtocart: true,
        //     quantity: param.items[0].quantity,
        //     product_price: param.items[0].price.toString(),
        //     prize_title: param.items[0].item_name,
        //     product_name: param.items[0].item_id,
        //     campaign_id: param.items[0].item_id,
        //     currency: param.currency,
        //     price_num: param.items[0].price
        // });
        if (typeof ttq !== 'undefined' && ttq) {
            ttq.track('AddToCart', {
                contents: [
                    {
                        content_id: param.items[0].item_id,
                        content_name: param.items[0].item_name,
                        quantity: param.items[0].quantity,
                        price: param.items[0].price.toString()
                    }
                ],
                content_type: 'product',
                value: param.items[0].price,
                currency: param.currency
            });
        }
    });

    $('body').on('GTM:removeCart', function (e, i) {
        var eventNameParent = i.removeFromCartGtag;
        var eventName = eventNameParent.event;
        var $module = $(e).data('module');
        var param = JSON.parse(eventNameParent.param);

        param.event_label =
            Desktop && $module
                ? $module + ' - ' + param.event_label
                : param.event_label;

        gtag('event', eventName, param);

        // Analytics.infobhipTrack('removefromcart', {
        //     quantity: param.items[0].quantity.toString(),
        //     product_price: param.items[0].price.toString(),
        //     prize_title: param.items[0].item_name,
        //     product_name: param.items[0].item_id,
        //     campaign_id: param.items[0].item_id
        // });

        Analytics.adjustSendEvent({
            eventToken: $.adjustTrack.deleteFromCart
        });
        CUSTOM_SNAPCHAT.trackUserEvents(CUSTOM_SNAPCHAT.SNAPCHAT_EVENT_NAME.removeFromCart, param);
        CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.removeFromCart, param);
    });

    /**
     * trigger event whenever a product is removed
     */
    $('body').on('cart:removeFromCartGTM', function (e, response) {
        var eventName = $(response)
            .parent()
            .find('.cart-quantity')
            .attr('data-gtag-remove-event');
        var param = $(response)
            .parent()
            .find('.cart-quantity')
            .attr('data-gtag-remove-param');
        var $module = $(response).data('module');
        param = JSON.parse(param);

        param.event_label = Desktop
            ? $module + ' - ' + param.event_label
            : param.event_label;
        if (eventName && param) {
            gtag('event', eventName, param); // eslint-disable-line no-undef

            Analytics.adjustSendEvent({
                eventToken: $.adjustTrack.removeToCart
            });
        }
    });

    $('body').on('click', '.fullView', function (e) {
        var $this = $(this);
        var $parent = $this.closest('.product-quickview');
        var pid = $parent.data('pid');
        var title = $parent.find('.prizeTitle b').text();
        var $module = 'Explore Campaigns';

        var param = {
            content_type: 'product',
            event_label: 'Quick View - ' + $module + ' - ' + title,
            event_category: 'engagement',
            items: [
                {
                    id: pid,
                    name: title,
                    list_name: $module,
                    brand: 'Idealz UAE',
                    category: $module
                }
            ]
        };

        if (param) {
            gtag('event', 'view_item', param); // eslint-disable-line no-undef
        }
    });

    $('body').on(
        'click',
        '.closing-soon__item .js-analytics-track',
        function () {
            var $this = $(this);
            var $parent = $this.closest('.closing-soon__item');
            var pid = $parent.data('pid');
            var title = $parent.find('.closing-soon__item-title').text();
            var parentIndex = $this.closest('.swiper-slide').index() + 1;
            var $module = $this.attr('data-module');

            if ($module === null || typeof $module === 'undefined') {
                $module = 'Explore Campaigns';
            }

            var param = {
                content_type: 'product',
                event_label: 'Closing Soon - ' + $module + ' - ' + title,
                event_category: 'engagement',
                items: [
                    {
                        id: pid,
                        name: title,
                        list_name: $module,
                        brand: 'Idealz UAE',
                        category: $module,
                        list_position: parentIndex
                    }
                ]
            };

            if (param) {
                gtag('event', 'view_item', param); // eslint-disable-line no-undef
            }
        }
    );

    $('body').on(
        'click',
        '.product-detail .campaign-image a, .product-detail .link-text a, .campaign-action a',
        function (e) {
            var $this = $(this);
            var parent = $this.closest('.product-detail');
            var pid = parent.data('pid');
            var title = Desktop
                ? parent.find('.prize-title b').text()
                : parent.find('h3').text();
            var parentIndex = Desktop
                ? parent.parent().index() + 1
                : parent.index() + 1;
            var $module = $this.attr('data-module');

            if ($module === null || typeof $module === 'undefined') {
                $module = 'Explore Campaigns';
            }

            var param = {
                content_type: 'product',
                event_label: $module + ' - ' + title,
                event_category: 'engagement',
                items: [
                    {
                        id: pid,
                        name: title,
                        list_name: $module,
                        brand: 'Idealz UAE',
                        category: $module,
                        list_position: parentIndex
                    }
                ]
            };

            if (param) {
                gtag('event', 'view_item', param); // eslint-disable-line no-undef
                CUSTOM_SNAPCHAT.trackUserEvents(CUSTOM_SNAPCHAT.SNAPCHAT_EVENT_NAME.viewContent, param);
            }
        }
    );

    // Event for product
    // ------------------------------------------
    $('body').on('click', '.product-overlay', function (e) {
        var $this = $(this);
        var title = $this.find('p').text();

        Analytics.gaSendEvent(
            'engagement',
            'click',
            'Idealz Product | ' + $.trim(title)
        );
    });

    // Event for Show More Winners
    // ------------------------------------------
    $('body').on('click', '#home-show-more', function () {
        var $this = $(this);
        var title = $this.text();
        Analytics.gaSendEvent(
            'engagement',
            'click',
            'Winner Pagination | ' + $.trim(title)
        );
    });

    // Event for Continuing for shipping
    // ------------------------------------------
    $('body').on('click', '.js-continue-to-shipping-mobile', function () {
        gtag('event', 'set_checkout_option', {
            checkout_option: 'Payment Selected - Continue to Shipping',
            checkout_step: 2
        });

        Analytics.adjustSendEvent({
            eventToken: $.adjustTrack.continuePayment
        });
    });

    // Event for Submit payment
    // ------------------------------------------
    $('#submitPaymentCartBtn, .js-continue-to-payment').click(function (e) {
        var $gtagElem = $('span[data-gtag-event].begin_checkout');
        var param = $gtagElem.attr('data-gtag-param');
        param = JSON.parse(param);
        var newArr = [];
        gtag('event', 'begin_checkout', param);

        $.each(param.items, function (index, item) {
            var newObj = {};
            newObj.content_id = item.item_id;
            newObj.content_name = item.item_id;
            newObj.quantity = item.quantity;
            newObj.price = item.price;

            newArr.push(newObj);
        });

        if (typeof ttq !== 'undefined' && ttq) {
            if ($(this).hasClass('new-card-button')) {
                ttq.track('AddPaymentInfo', {
                    contents: newArr,
                    content_type: 'product',
                    value: param.value,
                    currency: param.currency
                });
            }

            ttq.track('CompletePayment', {
                contents: newArr,
                content_type: 'product',
                value: param.value,
                currency: param.currency
            });
        }
    });

    // Event for selecting shipping
    // ------------------------------------------
    $('body').on('GTM:selectShipping', function () {
        var selectedShipOption = $(
            '.shipping-page-details .delivery-box.checked'
        );
        var shipValue = $(selectedShipOption).data('shipvalue');
        var shipOption = '';
        if (shipValue === 2) {
            shipOption = 'pickup';
        } else if (shipValue === 3) {
            shipOption = 'shipping';
        }

        Analytics.adjustSendEvent({
            eventToken: $.adjustTrack.selectShipping
        });

        Analytics.gaSendEvent(
            'engagement',
            'click',
            'Shipment change - ' + shipOption
        );
    });

    // Event for donating or not
    // ------------------------------------------
    $('body').on('GTM:Donate', function (e, i) {
        Analytics.adjustSendEvent({
            eventToken: $.adjustTrack.selectShipping
        });

        Analytics.gaSendEvent('engagement', 'click', 'Donate - ' + i.donate);
    });

    // Event for selecting ipoints
    // ------------------------------------------
    $('body').on('GTM:ipoints', function () {
        Analytics.adjustSendEvent({
            eventToken: $.adjustTrack.selectiPointsPayment
        });
    });

    // Event for new card
    // ------------------------------------------
    $('body').on('GTM:newcard', function () {
        Analytics.adjustSendEvent({
            eventToken: $.adjustTrack.newCardPayment
        });
    });

    // Event to open saved cards
    // ------------------------------------------
    $('body').on('GTM:opensavedcard', function () {
        Analytics.adjustSendEvent({
            eventToken: $.adjustTrack.saveCardPayment
        });
    });

    // Event for Payment failed
    // -------------------------------------------
    $(document).on('GTM:checkout:paymentError', function (e, i) {
        var Amount = $('.js-grand-total').text();
        Amount = Amount.slice(3).replace(/,/g, '');
        Analytics.gaSendEvent(
            'ecommerce',
            'click',
            'Payment Failed - Message=' + i.errorMessage
        );
        var card = $('.select-payment-method-box.selected').attr('id');
        // Analytics.infobhipTrack('purchase', { order_value: parseInt(Amount), payment_mode: card, payment_failed_error: i.errorMessage });
        Analytics.adjustSendEvent({
            eventToken: $.adjustTrack.paymentFail
        });
        CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_SNAPCHAT.SNAPCHAT_EVENT_NAME.purchaseFailed);
        CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.purchaseFailed);
    });

    // Event for Payment Success
    // -------------------------------------------
    $(document).on('GTM:checkout:paymentSuccess', function (e, i) {
        var Amount = $('.js-grand-total').text();
        var currency = Amount.slice(0, 3);
        Amount = Amount.slice(3).replace(/,/g, '');
        var card = $('.select-payment-method-box.selected').attr('id');

        // Analytics.infobhipTrack('purchase', { order_value: parseInt(Amount), payment_mode: card });
        Analytics.adjustSendEvent({
            eventToken: $.adjustTrack.paymentSuccess,
            revenue: parseInt(Amount),
            currency: currency
        });
    });

    // Event for use app in header
    // -------------------------------------------
    // $('.js-app-button').on('click', function (e) {
    //     e.preventDefault();
    //     Analytics.adjustSendEvent({
    //         eventToken: $.adjustTrack.useApp
    //     });
    // });

    // Event for mini cart checkout
    // -------------------------------------------
    $(document).on('GTM:miniCartOpen', function () {
        $(document).on('click', '.minicart .checkout-btn', function (e) {
            var $itemBlock = $('.minicart  .item-block');
            var items = [];
            var item = $('.minicart').find('.total-item-qunatity').text();
            var ticket = $('.minicart').find('#total-tickets-quantity').text();
            item = $.trim(item);
            ticket = $.trim(ticket);
            var label =
                'Proceed To Cart - Items: ' + item + ' - Tickets: ' + ticket;
            $itemBlock.each(function (index) {
                var $itemBlock = {};
                var $this = $(this);
                var price = $this.find('.unit-price .value').text();
                price = $.trim(price);
                $itemBlock.id = $this.data('pid');
                $itemBlock.name = $this
                    .find('.item-content-container h3')
                    .text();
                $itemBlock.brand = 'Idealz';
                $itemBlock.list_position = index + 1;
                $itemBlock.list_name = 'Mini Cart';
                $itemBlock.quantity = parseInt(
                    $this.find('.cart-quantity').val()
                );
                $itemBlock.price = price.substring(3).replace(/,/g, '');
                ($itemBlock.category = 'product'),
                ($itemBlock.variant = 'normal'),
                items.push($itemBlock);
            });
            gtag('event', 'view_item_list', {
                event_label: label,
                items: items
            });
        });
    });

    $(document).on('.marketing-btn-get-started', 'click', function () {
        gtag('event', 'click', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label: 'Idealz Website Learn - Get Started'
        });
    });

    $(document).on('click', '.markting-welcome .how-btn', function () {
        gtag('event', 'click', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label: 'Idealz Website Learn - How it works'
        });
    });

    $(document).on('click', '.marketing-btn-arabic:not(.english)', function () {
        gtag('event', 'click', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label: 'Idealz Website Learn - Switch to Arabic'
        });
    });

    $(document).on('click', '.marketing-btn-arabic.english', function () {
        gtag('event', 'click', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label: 'Idealz Website Learn - Switch to English'
        });
    });

    $(document).on(
        'click',
        '.marketing-real-winners-copy .see-all',
        function () {
            gtag('event', 'click', {
                // eslint-disable-line no-undef
                event_category: 'engagement',
                event_label: 'Idealz Website Learn - See all winners'
            });
        }
    );

    $(document).on('click', '.marketing-steps .steps-tab li a', function () {
        var $this = $(this);
        var $text = $this.data('tab');
        gtag('event', 'click', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label: 'Idealz Website Learn - ' + $text + ' tab'
        });
    });

    $(document).on('click', '.marketing-event-post .read-more', function () {
        var $this = $(this);
        var $text = $this.closest('.marketing-event-post').find('h3').text();
        gtag('event', 'click', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label: 'Idealz Website Learn - Read more for ' + $text
        });
    });

    $(document).on(
        'click',
        '.offers-home-section .swiper-slide .offers-section .cta-offers',
        function (e) {
            var $this = $(this);
            var $parent = $this.parent();
            var $text = $parent
                .find('.offers-copy h5')
                .html()
                .replace('<br>', ' ')
                .replace(/\s+/g, ' ')
                .trim();
            gtag('event', 'click', {
                // eslint-disable-line no-undef
                event_category: 'engagement',
                event_label: 'Homepage Offer - ' + $text
            });
        }
    );

    $(document).on('click', '.active-offer .offer-btn', function (e) {
        var $this = $(this);
        var $parent = $this.parent();
        var $text = $parent.find('.mainheading').text();
        var $text2 = $parent.find('.subtitle').text();
        gtag('event', 'click', {
            // eslint-disable-line no-undef
            event_category: 'engagement',
            event_label: 'Offers Page - ' + $text + ' ' + $text2
        });
    });

    $(document).on('click', '.js-close-weekly-popup', function (e) {
        e.preventDefault();
        var $this = $(this);

        if ($this.hasClass('cancel')) {
            gtag('event', 'click', {
                // eslint-disable-line no-undef
                event_category: 'engagement',
                event_label: 'Weekly winner Cancel'
            });
        } else {
            gtag('event', 'click', {
                // eslint-disable-line no-undef
                event_category: 'engagement',
                event_label: 'Add Weekly Winner'
            });
        }
    });

    $(document).on(
        'click',
        '#sortBox.global-navigation-dropdown ul li a',
        function () {
            var $this = $(this);
            var $text = $this.text().replace(/\s+/g, ' ').trim();
            gtag('event', 'click', {
                // eslint-disable-line no-undef
                event_category: 'engagement',
                event_label: 'Sort By - ' + $text
            });
        }
    );
});
