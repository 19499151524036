/* eslint-disable no-undef */
'use strict';
var errorLabel = require('./errorLabels'); // eslint-disable-line

module.exports = {
    globalFormSettings: function () {
        $.validator.setDefaults({
            validClass: 'valid',
            errorClass: 'invalid',
            errorPlacement: function (error, element) {
                var errorText = $(error).text();
                var labelText = $(element).closest('.input-field,.custom-select,.numberField').find('label').data('label');
                $(element).closest('.input-field,.custom-select,.numberField').find('label').show();
                if (error) {
                    $(element).closest('.input-field,.custom-select,.numberField').find('label').text(errorText);
                    $(element).closest('.input-field,.custom-select,.numberField').find('label').addClass('invalid');
                    $(element).closest('.input-field,.custom-select,.numberField').addClass('invalid');
                } else {
                    $(element).closest('.input-field,.custom-select,.numberField').find('label').text(labelText);
                    $(element).closest('.input-field,.custom-select,.numberField').find('label').removeClass('invalid');
                    $(element).closest('.input-field,.custom-select,.numberField').removeClass('invalid');
                    $(element).closest('.input-field,.custom-select,.numberField').find('label').show();
                }
            },
            success: function (label, element) {
                var labelText = $(element).closest('.input-field,.custom-select,.numberField').find('label').data('label');
                $(element).closest('.input-field,.custom-select,.numberField').find('label').addClass('valid');
                $(element).closest('.input-field,.custom-select,.numberField').find('label').show();
                $(element).closest('.input-field,.custom-select,.numberField').find('label').text(labelText);
                $(element).closest('.input-field,.custom-select,.numberField').find('label').removeClass('invalid');
                $(element).closest('.input-field,.custom-select,.numberField').removeClass('invalid');
                $(element).removeClass('is-invalid');
            }
        });

        $.validator.addMethod(
            'regex',
            function (value, element, regexp) {
                var re = new RegExp(regexp);
                return this.optional(element) || re.test(value);
            }, resources.Global$invalidEmail
        );

        $.validator.addMethod(
            'onlySpace',
            function (value) {
                if (value.startsWith(' ')) return false;

                return true;
            }, resources.Global$noSpaceAllowes
        );

        $.validator.addMethod(
            'spaceAllowed',
            function (value) {
                if (!value.includes(' ')) return false;

                return true;
            },
            resources.Global$noSpaceAllowes
        );

        $.validator.addMethod(
            'dateFormat',
            function (value) {
                var split = value.split('/');
                var mm = +split[0];
                var yy = +split[1];
                if (isNaN(mm) || isNaN(yy)) return false;
                var today = new Date();
                var cm = today.getMonth() + 1;
                var cy = today.getFullYear();
                cy = cy.toString().substr(2, 2);

                if (mm <= cm && yy < cy) return false;
                if (mm > 12) return false;
                if (yy < cy || (yy === cy && mm <= cm)) return false;

                return true;
            }, resources.Global$dateInvalid
        );

        $.validator.addMethod(
            'notEqualTo',
            function (value, element, param) {
                return this.optional(element) || value !== $(param).val();
            }, resources.Global$passwordNotequalTo

        );
    }
};
