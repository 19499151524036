

module.exports = {
    menuSwipe: function () {
        $(document).on('click', '.js-open-menu', function(e) {
            e.preventDefault();
            var $this = $('.js-site-header-open');

            if ($this.hasClass('open')) {
                $this.removeClass('open');
            } else {
                $this.addClass('open');
            }
        });
        $('body').on('mouseup', function (e) {
            var container = $('.js-site-header-open');
            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.removeClass('open');
            }
        });
    }
};