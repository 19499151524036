/* eslint-disable no-undef */
'use strict';
module.exports = {
    showDropDown: function () {
        $(document).on('globalDropdown:show', function (event, className) {
            $('.overlay').addClass('show');
            $('.global-dropdown.' + className).addClass('show');
            $('body').addClass('scroll-lock');
        });
    },
    closeDropDown: function () {
        $('body').on('mouseup', function (e) {
            var container = $('.global-dropdown.show');
            if (container.length > 0) {
                // if the target of the click isn't the container nor a descendant of the container
                if (
                    !container.is(e.target) &&
                    container.has(e.target).length === 0
                ) {
                    $(container).removeClass('show');
                    $('.overlay').removeClass('show');
                    $('body').removeClass('scroll-lock');
                }
            }
        });
    }
};
