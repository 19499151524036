'use strict';

$(document).ready(function () {
    var userID = typeof (GTM_CUSTOMER) !== 'undefined' && GTM_CUSTOMER ? GTM_CUSTOMER.customerNo : null; // eslint-disable-line no-undef
    var userEmail = typeof (GTM_CUSTOMER) !== 'undefined' && GTM_CUSTOMER ? GTM_CUSTOMER.customerEmail : null; // eslint-disable-line no-undef
    var currencySelected = $('.page').attr('data-current-currency');
    var Desktop = $(window).width() > 767;
    var gtagElems = $('span[data-gtag-event]');
    gtagElems.each(function () {
        if (typeof fbq !== 'undefined') {
            var eventName = $(this).data('gtag-event');
            var $info = $(this).data('gtag-param');
            if (eventName === 'purchase') {
                // var $paymentTotalAmount = $('.orderTotal-box.last .box-right.bigger').data('value').substring(3);
                var $id = [];
                var $totalQTY = 0;

                $('.purchasedItem:not(.header)').each(function () {
                    var $get = $(this).data('pid');
                    $totalQTY += parseInt($(this).data('qty'));
                    $id.push($get);
                });

                fbq('track', 'Purchase', {
                    transaction_id: $info.transaction_id,
                    value: $info.value,
                    content_ids: $id,
                    currency: currencySelected,
                    num_items: $totalQTY,
                    content_type: 'product'
                });
            }
        }
    });

    $(document).on('click', '.js-add-to-cart, .js-new-add-to-cart-btn, .js-new-add-cart, .closing-add-to-cart', function () {
        // if (Desktop) {
        if (typeof fbq !== 'undefined') {
            var $this = $(this);
            var $parent = $this.closest('.product-detail');
            var $data = $(this).data('gtag-add-param') || $this.data('gtagParam');
            if (!$data) {
                return;
            }
            var $dName = $data.items[0].item_name;
            var $dPrice = $data.items[0].price;
            var campID = $parent.data('pid') || $parent.data('product-id');
            var $name = Desktop ? $parent.find('.prize-information .block-title').text() : $parent.find('h3').text();
            var $value = $parent.find('.campaign-price .value').attr('content');
            if ($('.campaign-detail').length > 0) {
                $name = Desktop ? $('.detail-prize-information .inner-detail-container h3').text() : $('#content1').find('h4 b').text();
                $value = Desktop ? $value : $parent.find('.price .value').attr('content');
            }

            fbq('track', 'AddToCart', {
                content_ids: [campID],
                content_name: $dName === '' || $dName === undefined ? $name : $dName,
                value: $dPrice === undefined || $dPrice === '' ? $value : $dPrice,
                content_type: 'product',
                currency: Desktop ? currencySelected : $data.currency
            });
        }
        // }
    }).on('click', '.quantity-form .js-plus', function () {
        if (typeof fbq !== 'undefined') {
            var $this = $(this);
            var $parent = $this.closest('.item-block');
            var campID = $parent.data('pid');
            var $name = $parent.find('.item-content-container h3').text();
            var $value = $parent.find('.unit-price .value').attr('content');
            var $data = $(this).data('gtag-add-param');
            $data = $data === undefined ? $(this).data('gtag-param') : $data;
            var $dName = $data.items[0].item_name;
            var $dPrice = $data.items[0].price;

            fbq('track', 'AddToCart', {
                content_ids: [campID],
                content_type: 'product',
                content_name: $dName === '' || $dName === undefined ? $name : $dName,
                value: $dPrice === undefined || $dPrice === '' ? $value : $dPrice,
                currency: Desktop ? currencySelected : $data.currency
            });
        }
    }).on('click', '.closing-minus-to-cart, .js-new-minus', function () {
        if (typeof fbq !== 'undefined') {
            var $this = $(this);
            var $parent = $this.closest('.product-detail');
            var campID = $parent.data('pid') || $parent.data('product-id');
            var $name = Desktop ? $parent.find('.prize-information .block-title').text() : $parent.find('h4').text();
            var $value = $parent.find('.campaign-price .value').attr('content');
            if ($('.campaign-detail').length > 0) {
                $name = Desktop ? $('.detail-prize-information .inner-detail-container h3').text() : $('#content1').find('h4 b').text();
                $value = Desktop ? $value : $parent.find('.price .value').attr('content');
            }

            var $data = $(this).parent().find('.js-new-add-cart, .closing-add-to-cart').data('gtag-add-param');
            if (!$data) {
                return;
            }
            var $dName = $data.items[0].item_name;
            var $dPrice = $data.items[0].price;


            fbq('trackCustom', 'MinusFromCart', {
                content_ids: [campID],
                content_type: 'product',
                content_name: $dName === '' || $dName === undefined ? $name : $dName,
                value: $dPrice === undefined || $dPrice === '' ? $value : $dPrice,
                currency: Desktop ? currencySelected : $data.currency
            });
        }
    })
        .on('click', '.quantity-form .js-minus', function () {
            if (typeof fbq !== 'undefined') {
                var $this = $(this);
                var $parent = $this.closest('.item-block');
                var campID = $parent.data('pid');
                var $name = Desktop ? $parent.find('.prize-section .block-title').text() : $parent.find('.item-content-container h3').text();
                var $value = Desktop ? $parent.find('.price .value').attr('content') : $parent.find('.unit-price .value').attr('content');
                var $data = $(this).parent().find('.js-plus').data('gtag-add-param');
                $data = $data === undefined ? $(this).parent().find('.js-plus').data('gtag-param') : $data;
                var $dName = $data.items[0].item_name;
                var $dPrice = $data.items[0].price;


                fbq('trackCustom', 'MinusFromCart', {
                    content_ids: [campID],
                    content_type: 'product',
                    content_name: $dName === '' || $dName === undefined ? $name : $dName,
                    value: $dPrice === undefined || $dPrice === '' ? $value : $dPrice,
                    currency: Desktop ? currencySelected : $data.currency
                });
            }
        })
        .on('cart:removeItem', function (e, i) {
            if (typeof fbq !== 'undefined') {
                fbq('trackCustom', 'RemoveCampaignFromCart', {
                    content_ids: [i.pid],
                    content_type: 'product',
                    currency: currencySelected
                });
            }
        })
        .on('click', '.js-add-to-wish-list:not(.added)', function (e, i) {
            if (typeof fbq !== 'undefined') {
                var $this = $(this);
                var $parent = $this.closest('.product-detail');
                var campID = $parent.data('pid');
                var $name = $parent.find('.bundle-item.product-detail').text().trim();
                var $value = $parent.find('.prize-qty-section .value').attr('content');
                // if ($('.campaign-detail').length > 0) {
                //     $name = Desktop ? $('.detail-prize-information .inner-detail-container h3').text() : $('#content1').find('h4 b').text();
                //     $value = Desktop ? $value : $parent.find('.price .value').attr('content');
                // }

                fbq('track', 'AddToWishlist', {
                    content_ids: [campID],
                    content_name: $name,
                    content_type: 'product',
                    value: $value,
                    currency: currencySelected
                });
            }
        })
        .on('click', '.js-add-to-wish-list.added', function (e, i) {
            if (typeof fbq !== 'undefined') {
                var $this = $(this);
                var $parent = $this.closest('.product-detail');
                var campID = $parent.data('pid');
                var $name = $parent.find('.bundle-item.product-detail').text().trim();
                var $value = $parent.find('.prize-qty-section .value').attr('content');
                fbq('trackCustom', 'RemoveToWishlist', {
                    content_ids: [campID],
                    content_name: $name,
                    content_type: 'product',
                    value: $value,
                    currency: currencySelected
                });
            }
        })
        .on('click', '.js-delete-wishlist', function (e, i) {
            if (typeof fbq !== 'undefined') {
                var $this = $(this);
                var $parent = $this.closest('.content-container');
                var campID = $parent.data('pid');
                var $name = Desktop ? $parent.find('.prize-information .block-title').text() : $parent.find('h3').text();
                var $value = $parent.find('.campaign-price .value').attr('content');
                if ($('.campaign-detail').length > 0) {
                    $name = Desktop ? $('.detail-prize-information .inner-detail-container h3').text() : $('#content1').find('h4 b').text();
                    $value = Desktop ? $value : $parent.find('.price .value').attr('content');
                }

                fbq('trackCustom', 'RemoveToWishlist', {
                    content_ids: [campID],
                    content_name: $name,
                    content_type: 'product',
                    value: $value,
                    currency: currencySelected
                });
            }
        })
        .on('login:error', function (event, data) {
            if (typeof fbq !== 'undefined') {
                fbq('trackCustom', 'LoginFailed');
            }
        })
        .on('login:success', function (event, data) {
            if (typeof fbq !== 'undefined') {
                fbq('trackCustom', 'LoginSuccess');
            }
        })
        .on('click', '.logout-btn, .logoutIcon', function () {
            if (typeof fbq !== 'undefined') {
                fbq('track', 'Logout');
            }
        })
        .on('register:error', function (event, data) {
            if (typeof fbq !== 'undefined') {
                fbq('trackCustom', 'RegistrationFailed');
            }
        })
        .on('register:success', function (event, data) {
            if (typeof fbq !== 'undefined') {
                fbq('track', 'CompleteRegistration');
            }
        })
        .on('verification:success', function (event, data) {
            if (typeof fbq !== 'undefined') {
                fbq('trackCustom', 'SMS Send Success');
            }
        })
        .on('verification:error', function (event, data) {
            if (typeof fbq !== 'undefined') {
                fbq('trackCustom', 'SMS Send Fail');
            }
        })
        .on('country:success', function (event, data) {
            if (typeof fbq !== 'undefined') {
                fbq('trackCustom', 'Setting Page - Change Language');
            }
        })
        .on('currency:success', function (event, data) {
            if (typeof fbq !== 'undefined') {
                fbq('trackCustom', 'Setting Page - Change Currency');
            }
        })
        .on('click', '#mobile-register-now-button', function (event, data) {
            if (typeof fbq !== 'undefined') {
                fbq('trackCustom', 'Register Page Mobile');
            }
        })
        .on('click', '#mobile-forgotPassword', function (event, data) {
            if (typeof fbq !== 'undefined') {
                fbq('trackCustom', 'Mobile Forgot Password');
            }
        })
        .on('click', '#password-reset, .js-password-help', function (event, data) {
            if (typeof fbq !== 'undefined') {
                fbq('trackCustom', 'Desktop Forgot Password');
            }
        })
        .on('click', '#submitEmailButton', function (e) {
            if (typeof fbq !== 'undefined') {
                fbq('trackCustom', 'Forgot Password initiated');
            }
        })
        .on('click', '.resend-password', function (e) {
            if (typeof fbq !== 'undefined') {
                fbq('trackCustom', 'Forgot password click resend');
            }
        })
        .on('click', '.js-mobileSettings', function (e) {
            if (typeof fbq !== 'undefined') {
                if ($(this).data('trigger') === '#ShareBox') {
                    fbq('trackCustom', 'Share Trigger');
                }
            }
        })
        .on('click', '.share-trigger', function (e) {
            if (typeof fbq !== 'undefined') {
                fbq('trackCustom', 'Share Trigger');
            }
        })
        .on('click', '#ShareBox a, .shareable-links a', function () {
            if (typeof fbq !== 'undefined') {
                var $this = $(this);
                var $parent = $this.closest('.product-detail');
                var campID = $parent.data('pid');
                var $data = $(this).data('share');
                var $name = Desktop ? $parent.find('.prize-information .block-title').text() : $parent.find('h3').text();

                if ($('.campaign-detail').length > 0) {
                    $name = Desktop ? $('.detail-prize-information .inner-detail-container h3').text() : $('#content1').find('h4 b').text();
                }

                fbq('trackCustom', 'Campaign Share', {
                    content_name: $data,
                    category_name: $name,
                    content_ids: campID
                });
            }
        })
        .on('click', '#register-tab', function () {
            if (typeof fbq !== 'undefined') {
                fbq('track', 'Register Tab');
            }
        })
        .on('click', '#login-tab', function () {
            if (typeof fbq !== 'undefined') {
                fbq('track', 'Login Tab');
            }
        })
        .on('click', '.primary-navigation a', function (e) {
            var $this = $(this);
            var $mitem = $this.hasClass('notLinkCampgin');
            var $products = $this.hasClass('notLinkProduct');
            if ($mitem) {
                fbq('track', 'ViewContent', {
                    content_name: 'Campaigns',
                    content_category: 'Header'
                });
            } else if ($products) {
                fbq('track', 'ViewContent', {
                    content_name: 'Products',
                    content_category: 'Header'
                });
            }
        })
        .on('checkout:paymentError', function () {
            if (typeof fbq !== 'undefined') {
                var totalAmount = Desktop ? $('.js-grand-total').text().substring(4) : $('.cart-section-item .js-grand-total').text().substring(4);
                if ($('#useIpoints').is(':checked')) {
                    var pointsval = true;
                    var partial = 'Points';
                } else {
                    var pointsval = false;
                    var partial = '';
                }
                if ($('#donate').is(':checked')) {
                    var donate = true;
                } else {
                    var donate = false;
                }
                fbq('trackCustom', 'Payment Failed', {
                    value: totalAmount,
                    points: pointsval,
                    donated: donate,
                    currency: currencySelected
                });
            }
        })
        .on('click', '.js-show-payment-page, #submitPaymentCartBtn:not(.mobile-button)', function () {
            if (typeof fbq !== 'undefined') {
                var pointsval = false;
                if ($('#useIpoints').is(':checked')) {
                    pointsval = true;
                }
                var totalAmount = $('.cart-section-item .js-grand-total').text();
                var totalQty = $('.js-total-quantity').text();
                var totalTickets = $('.js-ticket-quantity').text();
                var donate = false;
                if ($('#donate').is(':checked')) {
                    donate = true;
                }
                fbq('track', 'InitiateCheckout', {
                    currency: currencySelected,
                    points: pointsval,
                    num_items: parseInt(totalQty),
                    value: totalAmount,
                    total_tickets: parseInt(totalTickets),
                    donated: donate
                });
            }
        })
        .on('click', '.js-new-card-trigger, .js-add-new-card', function () {
            var $this = $(this);
            var $data = $this.data('gtag-add-param');
            $data = $data === undefined ? $this.data('gtag-param') : $data;
            if (typeof fbq !== 'undefined' && !!$data) {
                fbq('track', 'AddPaymentInfo', {
                    value: $data.value,
                    content_category: 'Payment',
                    currency: Desktop ? currencySelected : $data.currency
                });
            }
        })
        .on('click', '.dont-dontate-btn', function () {
            if (typeof fbq !== 'undefined') {
                fbq('trackCustom', 'Not Donated');
            }
        })
        .on('click', '.yes-dontate-btn', function () {
            if (typeof fbq !== 'undefined') {
                fbq('trackCustom', 'Donated');
            }
        })
        .on('click', '#update-profile-form .js-btn-save,.js-profile-update', function () {
            if (typeof fbq !== 'undefined') {
                fbq('trackCustom', 'Account Updated');
            }
        })
        .on('click', '.delete-confirmation-btn', function () {
            if (typeof fbq !== 'undefined') {
                fbq('trackCustom', 'Card Deleted');
            }
        })
        .on('click', '.js-btn-update-password, .change-password-form .btn-save', function () {
            if (typeof fbq !== 'undefined') {
                fbq('trackCustom', 'Password Updated');
            }
        })
        .on('click', '.icon-copy-content', function () {
            if (typeof fbq !== 'undefined') {
                fbq('trackCustom', 'Invitation code copied');
            }
        });
    $('#donate').change(function () {
        if (typeof fbq !== 'undefined') {
            if (this.checked) {
                fbq('trackCustom', 'Donated');
            } else {
                fbq('trackCustom', 'Not Donated');
            }
        }
    });
    $(document).on('click', '.js-handle-session-sharing', function () {
        if (typeof fbq !== 'undefined') {
            fbq('trackCustom', 'Switch to Modesh');
        }
    });
});
