window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./components/materialize'));
    processInclude(require('jquery-validation/dist/jquery.validate.min.js'));
    processInclude(require('./globalValidation'));
    processInclude(require('base/components/footer'));
    processInclude(require('./components/swipeContainer'));
    processInclude(require('./components/globalDropDown'));
    processInclude(require('./components/pageLoader'));
    processInclude(require('./components/sessionRedirect'));
    processInclude(require('./components/notifications'));
    processInclude(require('./analytics'));
    processInclude(require('./fbpixels'));
});

require('base/thirdParty/bootstrap');
require('./components/spinner');
require('./components/lozad');

$(window).scroll(function() {    
    var scroll = $(window).scrollTop();

    if (scroll >= 100) {
        $(".site-header ").addClass("scrolled");
    } else {
        $(".site-header ").removeClass("scrolled");
    }
});

$(document).on('click', '.js-back-to-top', function() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    return false;
});
