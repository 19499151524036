module.exports = {
    init: function () {
        setTimeout(function () {
            $('body, html').removeClass('no-scroll');
            $('.masterSpinnerContainer').fadeOut('fast', function () {
                $('.masterSpinnerContainer').remove();
            });
        }, 1000);
    },
    addRemoveLoader: function () {
        $('body').on('addLoader', function () {
            $.spinner().start();
        });
        $('body').on('removeLoader', function () {
            $.spinner().stop();
        });
    }
};
